import React, { useState, useEffect, useMemo } from "react";
import "./Page_5.css"; // 스타일링을 위한 CSS 파일 import

const Page_5 = () => {
  return (
    <div className="section page_5">
      <div className="background_div_odd" />
      <div className="background_cover_div_5" />
      <div className="centered_div_5">
        <img
          className="text_img_5"
          src="/images/pageTextImg5.png"
          alt="Sample Image"
        />
        <div className="text_div_5">
          <p>내 또래가 아닌 모임은 부담스러울 수 있죠</p>
          <p>순환선은 모임원의 연령대와 성별을 설정 할 수 있어요</p>
          <p>내 직종 또는 내 관심사 등 검색도 가능합니다.</p>
        </div>
        <img
          id="animation_img_5"
          className="animation_img_5"
          src="/images/animationImg5.png"
          alt="Sample Image"
        />
      </div>
    </div>
  );
};

export default Page_5;
