import React from "react";
import "./Page1.css"; // 스타일링을 위한 CSS 파일 import

const Page1 = () => {
  return (
    <div className="page1 section">
      <div className="centered-div">
        <img
          className="text-img1"
          src="/images/splash_500.png"
          alt="Sample Image"
        />
        {/*
        <img
          className="landing_img_1"
          src="/images/landing_img_1.png"
          alt="Sample Image"
        />
        */}
        <div className="qr_code_box">
          <p className="qr_code_text">QR코드 다운로드</p>
          <img
            className="qr_code"
            src="/images/qr_code.png"
            alt="Sample Image"
          />
        </div>
      </div>
    </div>
  );
};

export default Page1;
