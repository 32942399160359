// src/components/SNSButton.js
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./SNSButton.css";

const SNSButton = () => {
  const [showIcons, setShowIcons] = useState(false);

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  return (
    <div className="sns-button-container">
      <img
        onClick={toggleIcons}
        className="download-button"
        src="/images/sns_button.png"
        alt="Sample Image"
      />
      <CSSTransition
        in={showIcons}
        timeout={300}
        classNames="sns-icons"
        unmountOnExit
      >
        <div className="sns-icons-container">
          <a
            href="https://www.facebook.com/soonhwansun/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/facebook-icon.png"
              alt="Facebook"
              className="download-button"
            />
          </a>
          <a
            href="https://www.instagram.com/soonhwansun/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/instagram-icon.png"
              alt="Instagram"
              className="download-button"
            />
          </a>
          <a
            href="https://youtube.com/@soonhwansun1?si=iFfmVngkGXV7nY0X"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              /*
              onClick={(event) => {
                event.preventDefault(); // 페이지 이동을 막습니다.
                alert("준비중입니다.");
              }}
              */
              src="/images/youtube-icon.png"
              alt="youtube"
              className="download-button"
            />
          </a>
        </div>
      </CSSTransition>
    </div>
  );
};

export default SNSButton;
