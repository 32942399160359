import React, { useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Dots from "./Dots";
import Header from "./Header";
import Page1 from "./Page1";
import Page_2 from "./Page_2";
import Page_3 from "./Page_3";
import Page_4 from "./Page_4";
import Page_5 from "./Page_5";
import Page6 from "./Page6";
import AppVideos from "./AppVideos";
import Popup from "./components/Popup";
import ReactPixel from "react-facebook-pixel";

const options = {
  autoConfig: true,
  debug: false,
};

let isPixelInitialized = false;

function App() {
  useEffect(() => {
    if (!isPixelInitialized) {
      ReactPixel.init(1505353240172450, null, options);
      ReactPixel.pageView();
      isPixelInitialized = true; // 초기화 상태를 true로 설정
    }
  }, []);

  const Fullpage = () => {
    const handleLeave = (origin, destination, direction) => {
      const index = destination.index;
      const header = document.getElementById("fixed-header");
      const targetDot = document.getElementById(`dot_${index + 1}`);
      const dotsContainer = document.getElementById("dots-container");
      const fixedButtonContainer = document.getElementById(
        "fixedButtonContainer"
      );
      const switchImage = document.getElementById("switchImage");
      const imageOverlay = document.getElementById("imageOverlay");

      const allDots = document.getElementsByClassName("dot");
      Array.from(allDots).forEach((dot) => dot.classList.remove("dot_active"));

      const allEvenPage = document.getElementsByClassName(
        "background_div_even"
      );
      const allOddPage = document.getElementsByClassName("background_div_odd");
      const isOdd = (index + 1) % 2 !== 0;
      if (isOdd) {
        Array.from(allEvenPage).forEach((dot) =>
          dot.classList.remove("background_div_even_change")
        );
        Array.from(allOddPage).forEach((dot) =>
          dot.classList.remove("background_div_odd_change")
        );
      } else {
        Array.from(allEvenPage).forEach((dot) =>
          dot.classList.add("background_div_even_change")
        );
        Array.from(allOddPage).forEach((dot) =>
          dot.classList.add("background_div_odd_change")
        );
      }

      // Check if we're on the first section
      if (index === 0 || index === 5) {
        dotsContainer.classList.remove("show");
        fixedButtonContainer.classList.remove("show");
        switchImage.classList.remove("show");
        imageOverlay.classList.remove("show");
      } else {
        dotsContainer.classList.add("show");
        fixedButtonContainer.classList.add("show");
        switchImage.classList.add("show");
        imageOverlay.classList.add("show");
      }
      if (index === 5) {
        header.classList.add("fixed-header-hide");
      } else {
        header.classList.remove("fixed-header-hide");
      }
      if (targetDot) {
        targetDot.classList.add("dot_active");
      }
    };

    const afterLoad = (origin, destination, direction) => {
      const index = destination.index;
      const targetElement = document.getElementById(
        `animation_img_${index + 1}`
      );
      if (targetElement) {
        targetElement.classList.add(`animation_img_${index + 1}_active`);
      }
    };

    return (
      <>
        <ReactFullpage
          //fullpage options
          licenseKey={"6658FF48-92B14923-9395A90F-ABA1E266"}
          scrollingSpeed={1000} /* Options here */
          verticalCentered={false}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <Page1 />
                <Page_2 />
                <Page_3 />
                <Page_4 />
                <Page_5 />
                <Page6
                  currentPage={state?.destination?.index + 1}
                  fullpageApi={fullpageApi}
                />
              </ReactFullpage.Wrapper>
            );
          }}
          onLeave={handleLeave} // 페이지 이동 시 호출되는 콜백
          afterLoad={afterLoad}
        />
        <Dots />
        <Header />
        <AppVideos />
        <Popup imageSrc="/images/popup_image.png" />
      </>
    );
  };

  return <Fullpage />;
}

export default App;
