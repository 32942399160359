import React, { useState, useEffect, useMemo } from "react";
import "./Page_4.css"; // 스타일링을 위한 CSS 파일 import

const Page_4 = () => {
  return (
    <div className="section page_4">
      <div className="background_div_even" />
      <div className="background_cover_div_4" />
      <div className="centered_div_4">
        <img
          className="text_img_4"
          src="/images/pageTextImg4.png"
          alt="Sample Image"
        />
        <div className="text_div_4">
          <p>너무 먼 약속 장소..</p>
          <p>서울 횡단은 이제 그만!</p>
          <p>순환선의 모든 모임은 내 회사에서 걸어서 15분 이내</p>
          <p>내 회사의 위치 주변의 모임들만 보여드립니다.</p>
          <p>5분, 10분 거리를 설정 할 수도 있어요.</p>
        </div>
        <img
          id="animation_img_4"
          className="animation_img_4"
          src="/images/animationImg4.png"
          alt="Sample Image"
        />
      </div>
    </div>
  );
};

export default Page_4;
