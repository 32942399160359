import React, { useState, useEffect, useMemo } from "react";
import "./Page_3.css"; // 스타일링을 위한 CSS 파일 import

const Page_3 = () => {
  return (
    <div className="section page_3">
      <div className="background_div_odd" />
      <div className="background_cover_div_3" />
      <div className="centered_div_3">
        <img
          className="text_img_3"
          src="/images/pageTextImg3.png"
          alt="Sample Image"
        />
        <div className="text_div_3">
          <p>집과 회사, 반복되는 일상..</p>
          <p>퇴근 후 편하게 모임에 참여해보세요</p>
          <p>맛집투어, 스터디, 언어교환, 독서 모임 등</p>
          <p>참여해보고 싶었던 모임이 기다리고 있습니다.</p>
        </div>
        <img
          id="animation_img_3"
          className="animation_img_3"
          src="/images/animationImg3.png"
          alt="Sample Image"
        />
      </div>
    </div>
  );
};

export default Page_3;
