import React from "react";
import "./Header.css"; // 스타일링을 위한 CSS 파일 import
import { isAndroid, isIOS } from "react-device-detect";
import SNSButton from "./components/SNSButton";
import ReactPixel from "react-facebook-pixel";

const Header = () => {
  const handleDownloadApple = () => {
    console.log("ios 클릭됨");
    ReactPixel.track("Lead", {
      platform: "iOS",
      action: "Download",
    });
    window.location.href =
      "https://apps.apple.com/kr/app/%EC%88%9C%ED%99%98%EC%84%A0/id6476528397";
  };
  const handleDownloadAndroid = () => {
    console.log("android 클릭됨");
    ReactPixel.track("Lead", {
      platform: "Android",
      action: "Download",
    });
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.circularline251";
  };
  return (
    <header id="fixed-header" className="fixed-header">
      <img
        className="header_name_img"
        src="/images/header_name.png"
        alt="Sample Image"
      />
      <div className="header-right-box">
        {!isAndroid && (
          <img
            onClick={handleDownloadApple}
            className="download-button"
            src="/images/apple_download.png"
            alt="Sample Image"
          />
        )}
        {!isIOS && (
          <img
            onClick={handleDownloadAndroid}
            className="download-button"
            src="/images/android_download.png"
            alt="Sample Image"
          />
        )}
        <SNSButton />
      </div>
    </header>
  );
};

export default Header;
