// Dots.js

import React from "react";
import "./Dots.css"; // Dots 컴포넌트에 해당하는 CSS 파일 import

const Dots = () => {
  return (
    <div id="dots-container" className="dots-container">
      <div>
        <div id="dot_2" className="dot dot_2"></div>
        <div id="dot_3" className="dot dot_3"></div>
        <div id="dot_4" className="dot dot_4"></div>
        <div id="dot_5" className="dot dot_5"></div>
      </div>
    </div>
  );
};

export default Dots;
