import React, { useState, useRef, useEffect, memo } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const VideoModal = memo(({ modalIsOpen, closeModal, videoUrl }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="YouTube Video Modal"
    >
      <ReactPlayer url={videoUrl} playing={modalIsOpen} controls width="100%" />
    </Modal>
  );
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default VideoModal;
