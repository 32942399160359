import React, { useState, useEffect, useCallback } from "react";
import VideoModal from "./components/VideoModal";
import "./AppVideos.css"; // Dots 컴포넌트에 해당하는 CSS 파일 import

const videos = [
  {
    id: 1,
    url: "https://www.youtube.com/watch?v=XGBLsE9RLCE",
    buttonImage: "/images/guide_1.png",
  },
  {
    id: 2,
    url: "https://www.youtube.com/watch?v=Qg6DG3Il1Xw",
    buttonImage: "/images/guide_2.png",
  },
  {
    id: 3,
    url: "https://www.youtube.com/watch?v=PGp9sD-WUbg",
    buttonImage: "/images/guide_3.png",
  },
  {
    id: 4,
    url: "https://www.youtube.com/watch?v=frFOdh_Kea0",
    buttonImage: "/images/guide_4.png",
  },
];

const AppVideos = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoUrl, setVideoUrl] = useState(videos[0].url);

  useEffect(() => {
    setVideoUrl(videos[currentVideoIndex].url);
  }, [currentVideoIndex]);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const prevVideo = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
    );
  };
  return (
    <>
      <div id="fixedButtonContainer" className="fixedButtonContainer">
        <img
          src="/images/left_arrow.png"
          alt={`left_arrow`}
          onClick={prevVideo}
          className="arrowButton"
        />
        <img
          src={videos[currentVideoIndex].buttonImage}
          alt={`Video ${currentVideoIndex + 1} Button`}
          onClick={openModal}
          className="videoButton"
        />
        <img
          src="/images/right_arrow.png"
          alt={`right_arrow`}
          onClick={nextVideo}
          className="arrowButton"
        />
      </div>
      <VideoModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        videoUrl={videoUrl}
      />
    </>
  );
};
export default AppVideos;
