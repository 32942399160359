import React, { useState, useEffect } from "react";
import "./Page6.css"; // 스타일링을 위한 CSS 파일 import

const Page6 = ({ fullpageApi, currentPage }) => {
  const images = [
    "/images/randomImg_1.png",
    "/images/randomImg_2.png",
    "/images/randomImg_3.png",
    "/images/randomImg_4.png",
    "/images/randomImg_5.png",
    "/images/randomImg_6.png",
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [executedOnce, setExecutedOnce] = useState(false);
  const [landingOption, setLandingOption] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentPage === 6)
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  useEffect(() => {
    if (!fullpageApi || executedOnce) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const targetPage = queryParams.get("page");
    const targetOption = queryParams.get("option");
    if (targetPage) {
      fullpageApi.moveTo(targetPage);
      setExecutedOnce(true);
    }
    if (targetOption) {
      setLandingOption(targetOption);
    }
  }, [fullpageApi]);

  return (
    <footer className="section page5">
      <div className="container_div_5">
        <div className="item_box">
          <img
            className="footer_text_img"
            src="/images/footerTextImg.png"
            alt="Sample Image"
          />
          <div className="auto-image-slider">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                className={
                  index === currentImageIndex
                    ? "random_img visible"
                    : "random_img hidden"
                }
              />
            ))}
          </div>
        </div>
        <div>
          <div>
            <p>
              <span>주식회사 순환선</span>
              <br />
              <span>서울특별시 강남구 봉은사로21길 57-5, 401호</span>
            </p>
            <p>
              <span>사업자번호 : 773-88-02991</span>
            </p>
            <p>
              지원 : &nbsp;
              <a href="mailto:support@soonhwansun.com">
                <span
                  className={landingOption === "support" ? "highlight" : ""}
                >
                  support@soonhwansun.com
                </span>
              </a>
              <br />
              문의 : &nbsp;
              <a href="mailto:contact@soonhwansun.com">
                <span
                  className={landingOption === "contact" ? "highlight" : ""}
                >
                  contact@soonhwansun.com
                </span>
              </a>
            </p>
            <p>
              <span>copyright (c) soonhwansun.com all rights reserved.</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Page6;
