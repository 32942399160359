// Popup.js
import React, { useState, useEffect } from "react";
import "./Popup.css";

const Popup = ({ imageSrc }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    // 페이지가 로드될 때 팝업을 표시
    setIsPopupOpen(true);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };
  return (
    <>
      <img
        id="switchImage"
        src="/images/event_btn2.png"
        alt="Close"
        className="switchImage"
      />
      <div id="imageOverlay" className="imageOverlay" onClick={openPopup}></div>
      {isPopupOpen && (
        <div className="overlay">
          <div className="popup">
            <img
              src="/images/close_btn.png"
              alt="Close"
              className="closeButtonImage"
              onClick={closePopup}
            />
            <img src={imageSrc} alt="Popup" className="image" />
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
