import React, { useState, useEffect, useMemo } from "react";
import "./Page_2.css"; // 스타일링을 위한 CSS 파일 import

const Page_2 = () => {
  return (
    <div className="section page_2">
      <div className="background_div_even" />
      <div className="background_cover_div_2" />
      <div className="centered_div_2">
        <img
          className="text_img_2"
          src="/images/pageTextImg2.png"
          alt="Sample Image"
        />
        <div className="text_div_2">
          <p>항상 똑같은 메뉴와 사람들..</p>
          <p>새로운 사람들과 함께 점식식사를 해봐요</p>
          <p>내가 원하는 메뉴를 원하는 사람들과 식사합니다.</p>
          <p>각자의 메뉴를 먹을지 쉐어 할지 정할 수 있어요</p>
        </div>
        <img
          id="animation_img_2"
          className="animation_img_2"
          src="/images/animationImg2.png"
          alt="Sample Image"
        />
      </div>
    </div>
  );
};

export default Page_2;
